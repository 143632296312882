import {React, useEffect} from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Logo from "../images/404.svg"
import Background from "../images/bg.svg"
import Cursor from "../images/smallshroom.svg"
import Bye from "../images/bye.svg"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  useEffect(() => {



    window.onscroll = function() {
      if(window.scrollY >= 45){
      document.querySelector("#gatsby-focus-wrapper > header > div").style.top = '0px';
      document.querySelector("#gatsby-focus-wrapper > header > div").style.position = 'fixed'
    }else{document.querySelector("#gatsby-focus-wrapper > header > div").style.top = '0px'
    document.querySelector("#gatsby-focus-wrapper > header > div").style.position = 'relative'
    }
      };
    // for window scrolldown event
    window.onscroll = (()=> {

      let mybtn = document.getElementById("back-to-top");
      if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        mybtn.style.display = "block";
      } else {
        mybtn.style.display = "none";
      }

      
    });


  })

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="404: Not Found" />
      <section className="hero is-white is-large" style={{backgroundImage:`url(${Background})`,backgroundSize:'100%',backgroundRepeat:'no-repeat',backgroundPosition:'bottom'}}>
        <div className="hero-body">
          <div className="container has-text-centered">
            <img src={Logo} id="nav-logo" alt="logo" width="auto" style={{maxWidth:'300px',minWidth:'200px'}} />
            <h1 className="title">Not Found</h1>
            <p className="subtitle">You just hit a route that doesn&#39;t exist... the sadness sorry!</p>
            <p>

            </p>
            <br />
            <a href="/" className="button is-black is-outlined">
              <b>Go back</b>
              <span className="icon">
                <i className="contact-icon"><img src={Cursor} id="main-logo" alt="icon" width="auto" style={{maxWidth:'25px',minWidth:'20px'}} /></i>
              </span>
            </a>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {/*<a href="https://shop.shroom4you.com" target="_blank" rel="noopener noreferrer" className="button is-black is-outlined">
              <b>Visit shop</b>
              <span className="icon">
                <i className="contact-icon"><img src={Cart} id="main-logo" alt="icon" width="auto" style={{maxWidth:'25px',minWidth:'20px'}} /></i>
              </span>
            </a>*/}
          </div>
        </div>

        <div className="hero-foot">

        </div>
    </section>

    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
